define([
    'lodash',
    'warmupUtilsLib'
], function (
    _,
    warmupUtilsLib
) {
    'use strict';

    const references = {
        IMAGE_REF: 'image',
        SVG_REF: 'svg',
        RECT_REF: 'rect',
        PATTERN_REF: 'pattern',
        MASK_SVG_REF: 'mask-svg',
        MASK_USE_REF: 'mask-use'
    };

    function measureNodeImage(id, measureMap, nodesMap, parentStructureInfo) {
        let bBox;
        let filterId;
        let isSvgImage = false;

        nodesMap[id + references.IMAGE_REF] = nodesMap[id].querySelector('img') || nodesMap[id].querySelector('image');
        if (!nodesMap[id + references.IMAGE_REF]) {
            return;
        }

        const svgMaskId = nodesMap[id + references.IMAGE_REF].getAttribute('data-svg-mask');

        nodesMap[id + references.SVG_REF] = nodesMap[id].querySelector(`#svg${id}`); // Optional
        nodesMap[id + references.RECT_REF] = nodesMap[id + references.SVG_REF] && nodesMap[id + references.SVG_REF].querySelector('rect'); // Optional
        nodesMap[id + references.PATTERN_REF] = nodesMap[id].querySelector('pattern'); // Optional
        nodesMap[id + references.MASK_SVG_REF] = nodesMap[id].querySelector(`#${svgMaskId}`); // Optional
        nodesMap[id + references.MASK_USE_REF] = nodesMap[id].querySelector(`#${svgMaskId}-use`); // Optional

        // <image> is either for SVG filters IE/Edge fallback, or for SVG masks
        if (nodesMap[id + references.IMAGE_REF] && nodesMap[id + references.IMAGE_REF].nodeName.toLowerCase() === 'image') {
            isSvgImage = true;
            filterId = _.get(nodesMap[id].querySelector('filter'), 'id');

            if (nodesMap[id + references.MASK_SVG_REF]) {
                try {
                    bBox = nodesMap[id + references.MASK_SVG_REF].getBBox();
                } catch (e) {
                    if (window && window.document) {
                        const clone = nodesMap[id + references.MASK_SVG_REF].cloneNode(true);
                        clone.style.visibility = 'hidden';
                        clone.style.position = 'absolute';
                        window.document.body.appendChild(clone);
                        bBox = clone.getBBox();
                        clone.parentElement.removeChild(clone);
                    }
                }
            }
        }

        const imgSrc = nodesMap[id + references.IMAGE_REF].getAttribute(isSvgImage ? 'xlink:href' : 'src');

        measureMap.width[id] = nodesMap[id].offsetWidth;
        measureMap.height[id] = nodesMap[id].offsetHeight;
        const hasBgScrollEffect = nodesMap[id].getAttribute('data-has-bg-scroll-effect');

        if (hasBgScrollEffect) {
            measureMap.width[id] = nodesMap[parentStructureInfo.id].offsetWidth;
            measureMap.height[id] = Math.max(measureMap.height.screen, nodesMap[parentStructureInfo.id].offsetHeight);
        }
        measureMap.custom[id] = {
            isZoomed: nodesMap[id].getAttribute('data-image-zoomed'),
            isSvgImage,
            filterId,
            bBox,
            hasMask: Boolean(nodesMap[id + references.MASK_SVG_REF]),
            hasSvgNode: Boolean(nodesMap[id + references.SVG_REF]),
            imgSrc,
            parentId: parentStructureInfo.id,
            renderedStyles: nodesMap[id].getAttribute('data-style'),
            hasPattern: !!nodesMap[id + references.PATTERN_REF]
        };
    }

    return {
        measureNodeImage,
        references,
        getRootWidth: function getRootWidth(siteWidth, measureMap, rootId) {
            return warmupUtilsLib.layoutUtils.getRootWidth(measureMap, rootId, siteWidth);
        },
        getRootLeft: function getRootLeft(siteData, measureMap, rootId) {
            return warmupUtilsLib.layoutUtils.getRootLeft(measureMap, rootId, siteData.getSiteX());
        }
    };
});
