define([
    'lodash',
    'layout/util/layout',
    'layout/specificComponents/balataLayout'
], function (_, layout, balataLayout) {
    'use strict';

    function measureStateBoxState(id, measureMap, nodesMap, structureInfo) {
        measureMap.top[id] = 0;
        if (!structureInfo.designDataItem.background) {
            return;
        }

        balataLayout.measure(id, measureMap, nodesMap, structureInfo);
    }

    function patchStateBoxState(id, patchers, measureMap, structureInfo, siteData) {
        patchers.css(id, {width: '100%'});
        if (!structureInfo.designDataItem.background) {
            return;
        }

        balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
    }

    //the default flow is that the parent is measured first
    layout.registerRequestToMeasureDom('wysiwyg.viewer.components.StateBoxState');
    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.StateBoxState', [['inlineContentParent'], ['inlineContent']].concat(balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE));
    layout.registerCustomMeasure('wysiwyg.viewer.components.StateBoxState', measureStateBoxState);
    layout.registerPatcher('wysiwyg.viewer.components.StateBoxState', patchStateBoxState);

    layout.registerCustomMeasure('wysiwyg.viewer.components.StateStripState', measureStateBoxState);
    layout.registerPatcher('wysiwyg.viewer.components.StateStripState', patchStateBoxState);

    layout.registerRequestToMeasureDom('wysiwyg.viewer.components.StateBoxFormState');
    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.StateBoxFormState', [['inlineContentParent'], ['inlineContent']].concat(balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE));
    layout.registerCustomMeasure('wysiwyg.viewer.components.StateBoxFormState', measureStateBoxState);
    layout.registerPatcher('wysiwyg.viewer.components.StateBoxFormState', patchStateBoxState);
});
