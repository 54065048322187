define([
    'lodash',
    'warmupUtils',
    'experiment',
    'layout/specificComponents/bgImageLayout',
    'layout/specificComponents/bgOverlayLayout',
    'warmupUtilsLib',
    'layout/specificComponents/html5VideoLayout'
], function (
    _,
    warmupUtils,
    experiment,
    bgImageLayout,
    bgOverlayLayout,
    warmupUtilsLib,
    html5VideoLayout
) {
    'use strict';

    const {BALATA, MEDIA, MEDIA_PADDING, WEBGL, CANVAS, VIDEO, IMAGE, OVERLAY, POSTER, BG_COLOR, BG_IMAGE} = warmupUtilsLib.mediaConsts.balataConsts;
    const {containerBackgroundUtils} = warmupUtils;
    /**
     * Paths to pass to registerToMeasureChildren of balata parents
     * @type {*[]}
     */
    const BALATA_PATHS_TO_REQUEST_MEASURE = [
        [BALATA],
        [BALATA, MEDIA],
        [BALATA, MEDIA_PADDING],
        [BALATA, MEDIA, IMAGE],
        [BALATA, MEDIA, VIDEO],
        [BALATA, MEDIA, WEBGL],
        [BALATA, MEDIA, WEBGL, CANVAS],
        [BALATA, MEDIA, VIDEO, VIDEO],
        [BALATA, MEDIA, VIDEO, POSTER],
        [BALATA, OVERLAY],
        [BALATA, OVERLAY, OVERLAY],
        [BALATA, BG_COLOR],
        [BALATA, BG_COLOR, BG_COLOR]
    ];
    /**
     * Helper to build id names of balata and children
     * @param {string} parentId
     * @returns {{balataId: string, mediaId: string, imageId: string, videoId: string}}
     */
    function getBalataIds(parentId) {
        const balataId = parentId + BALATA;
        const mediaId = balataId + MEDIA;
        const imageId = mediaId + IMAGE;
        const videoId = mediaId + VIDEO;
        const posterId = mediaId + VIDEO + POSTER;
        const webglId = mediaId + WEBGL;
        const canvasId = mediaId + WEBGL + CANVAS;
        const mediaPaddingId = balataId + MEDIA_PADDING;
        return {balataId, mediaId, imageId, videoId, webglId, canvasId, mediaPaddingId, posterId};
    }

    /**
     * Default measures by parent measureMap and DOM
     * @param {object} measureMap
     * @param {HTMLElement} balataNode
     * @param {string} parentId
     * @param {number} pageLeft compensate for css transforms on the page
     * @returns {{top: number, left: number, width: *, height: *, absoluteLeft: number}}
     */
    function getDefaultMeasures(measureMap, balataNode, parentId, pageLeft) {
        const top = 0;
        const left = 0;
        // We have to get height from measureMap since it might be different than dom height
        const height = _.defaultTo(measureMap.height[parentId], balataNode.offsetHeight);

        return {
            top,
            left,
            height,
            width: balataNode.offsetWidth,
            absoluteLeft: balataNode.getBoundingClientRect().left - pageLeft
        };
    }

    /**
     * Default measures by self DOM measures
     * @param {HTMLElement} node
     * @param {HTMLElement} pageNode
     * @returns {{top: number, left: number, width, height, absoluteLeft: number}}
     */
    function getDomMeasures(node, pageNode) {
        return {
            top: 0,
            left: 0,
            width: node.offsetWidth,
            height: node.offsetHeight,
            absoluteLeft: node.getBoundingClientRect().left - pageNode.getBoundingClientRect().left
        };
    }

    /**
     * Get dimension saved to measuremap in measure phase
     * @param {object} measureMap
     * @param {string} balataId
     * @returns {{top: number, left: number, width: number, height: number, absoluteLeft: number}}
     */
    function getSavedMeasures(measureMap, balataId) {
        return {
            top: measureMap.top[balataId],
            left: measureMap.left[balataId],
            width: measureMap.width[balataId],
            height: measureMap.height[balataId],
            absoluteLeft: measureMap.absoluteLeft[balataId]
        };
    }
    /**
     * Adjust left position if effect demands it,
     * TODO: can we replace this with some css with kill980?
     * @param {string} bgEffectName
     * @param {string} renderFixedPosition
     * @param {number} absoluteLeft
     * @returns {number}
     */
    // function getLeftPosition(bgEffectName, renderFixedPosition, absoluteLeft) {
    //     return containerBackgroundUtils.isFullScreenByEffect(bgEffectName, !!renderFixedPosition) ? Math.floor(absoluteLeft) : 0;
    // }
    /**
     * Get style to patch
     * @param {{width: number, height: number}} measures
     * @param {boolean} useClipPath
     * @param {boolean} needsClipping
     * @returns {{clip: string} || {}} clip key and value
     */
    function getClipStyle(measures, useClipPath, needsClipping) {
        const balataStyle = {};
        if (!useClipPath && needsClipping) {
            balataStyle.clip = `rect(0px,${measures.width}px,${measures.height}px,0px)`;
        }
        return balataStyle;
    }


    /**
     * Measure function to call from DOM Only compliant parent component
     * @param parentId
     * @param measureMap
     * @param nodesMap
     * @param siteData
     * @param structureInfo
     */
    function measureDomOnly(parentId, measureMap, nodesMap, siteData, structureInfo) {
        const {balataId} = getBalataIds(parentId);
        const {pageId} = nodesMap[balataId].dataset;
        measure(parentId, measureMap, nodesMap, siteData, structureInfo, getDomMeasures(nodesMap[balataId], nodesMap[pageId]));
    }

    const isUsingWixImage = () => experiment.isOpen('bv_wixImagePhaseTwo');

    /**
     * Measure function to call from parent component
     * @param parentId
     * @param measureMap
     * @param nodesMap
     * @param structureInfo
     * @param parentDimensions
     */

    function measure(parentId, measureMap, nodesMap, structureInfo, parentDimensions) {
        const {balataId, mediaId, videoId/*, posterId*/} = getBalataIds(parentId);
        const hasBalata = !!nodesMap[balataId];
        if (!hasBalata) {
            return;
        }

        const {enableVideo, bgEffectName, mediaType, useClipPath, needsClipping, pageId} = nodesMap[balataId].dataset;
        const {mediaCompType, hasBgEffect} = mediaType ? nodesMap[mediaId].dataset : {};
        const pageLeft = nodesMap[pageId] ? nodesMap[pageId].getBoundingClientRect().left : 0;
        const measures = parentDimensions || getDefaultMeasures(measureMap, nodesMap[balataId], parentId, pageLeft);

        measureMap.custom[balataId] = {hasBalata, hasBgEffect, enableVideo, bgEffectName, mediaType, useClipPath, needsClipping, pageId, mediaCompType};


        _.forEach(measures, function (value, key) {
            measureMap[key][balataId] = value;
        });

        if (!mediaType) {
            return;
        }

        if (!isUsingWixImage()) {
            if (bgEffectName) {
                measureMap.width[mediaId] = measureMap.width[balataId];
            }

            if (mediaCompType === BG_IMAGE) {
                bgImageLayout.measureBgImageBalata(balataId, measureMap, nodesMap);
            }

            bgOverlayLayout.measure(balataId, measureMap, nodesMap);
        }

        if (mediaType === 'WixVideo' && enableVideo) {
            html5VideoLayout.measureBgVideo(mediaId, videoId, measureMap, nodesMap);
        }
    }

    /**
     * Patch function to call from parent layout
     * @param parentId
     * @param patchers
     * @param measureMap
     * @param structureInfo
     * @param siteData
     * @param parentDimensions
     */
    function patch(parentId, patchers, measureMap, structureInfo, siteData, parentDimensions) {
        const {balataId, mediaId, imageId, videoId, webglId, canvasId, mediaPaddingId} = getBalataIds(parentId);
        const {hasBalata, hasBgEffect, enableVideo, bgEffectName, mediaType, useClipPath, needsClipping, mediaCompType} = measureMap.custom[balataId] || {};
        if (!hasBalata) {
            return;
        }

        const measures = parentDimensions || getSavedMeasures(measureMap, balataId);

        //TODO: this re-takes parent height to make sure we know about anchors changes, will be irrelevant in mesh.
        if (!parentDimensions) {
            measures.height = _.defaultTo(measureMap.height[parentId], measures.height);
        }
        //patch balata
        const balataClip = getClipStyle(measures, useClipPath, needsClipping);
        patchers.css(balataId, balataClip);

        if (!mediaType) {
            return;
        }
        const isMediaPadding = _.isNumber(measureMap.height[mediaPaddingId]);
        const mediaContainerHeight = isMediaPadding ? measureMap.height[mediaPaddingId] : measures.height;
        const mediaHeight = containerBackgroundUtils.getHeightByEffect(bgEffectName, measureMap, mediaContainerHeight);
        //patch media wrapper
        if (measureMap.height[mediaPaddingId]) {
            const paddingClip = getClipStyle({width: measures.width, height: mediaHeight}, useClipPath, needsClipping);
            patchers.css(mediaPaddingId, paddingClip);
        }

        if (!isUsingWixImage()) {
            bgOverlayLayout.patch(balataId, patchers, measureMap);

            //patch media
            if (hasBgEffect) {
                patchers.css(mediaId, {
                    height: mediaHeight,
                    width: measureMap.width[balataId],
                    left: measures.absoluteLeft || 0
                });
            } else {
                //since React doesnt know about layout changes, we need to update back when removing effect
                patchers.css(mediaId, {
                    height: '100%',
                    width: '100%',
                    left: 0
                });
            }

            if (mediaCompType === BG_IMAGE) {
                bgImageLayout.patchBgImage(balataId, imageId, patchers, measureMap, siteData);
            }
        }

        if (mediaType === 'WixVideo' && enableVideo) {
            html5VideoLayout.patchBgVideo(
                videoId,
                patchers,
                measureMap,
                structureInfo,
                siteData,
                mediaHeight,
                {width: measures.width, height: measureMap.height[mediaPaddingId] || measures.height});
            if (_.isNumber(measureMap.width[webglId])) {
                patchers.css(canvasId, {
                    width: measureMap.width[`${videoId}video`],
                    height: measureMap.height[`${videoId}video`],
                    top: measureMap.top[`${videoId}video`],
                    left: measureMap.left[`${videoId}video`]
                });
            }
        }
    }

    return {
        BALATA_PATHS_TO_REQUEST_MEASURE,
        measure,
        measureDomOnly,
        patch
    };
});
